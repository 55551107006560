@import '@core/scss/core.scss';
@import '@core/scss/angular/libs/select.component.scss';
@import './assets/scss/styles';
@import './assets/scss/empresas/commons.scss';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import '@core/scss/angular/libs/flatpickr.component.scss';

:root {
  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: #f5f5f5;
}

// remove a escrita duplicada da paginação das tabelas
// tava colocando por exemplo 1 (current)
// .page-link {
//     .visually-hidden {
//         display: none;
//     }
// }

@for $i from 0 through 500 {
  $name: $i + 1;

  .h-#{$name}-px {
    height: #{$name + 'px'} !important;
  }
}

@for $i from 0 through 100 {
  $name: $i + 1;

  .h-#{$name}-percent {
    height: #{$name + '%'} !important;
  }
}

// cria 500 variavel diferente para width
@for $i from 0 through 500 {
  $name: $i + 10;

  .w-#{$name} {
    width: #{$name + 'px'} !important;
  }
}

// cria 100 width percentage variable;
@for $i from 0 through 100 {
  $name: $i + 1;

  .w-#{$name + '-percent'} {
    width: #{$name + '%'} !important;
  }
}

// create 100 max width percentage;
@for $i from 0 through 100 {
  $name: $i + 1;

  .mw-#{$name}-percent {
    max-width: #{$name + '%'} !important;
  }
}

// create 1000 max width pixel variable;
@for $i from 0 through 1000 {
  $name: $i + 1;

  .mw-#{$name} {
    max-width: #{$name + 'px'} !important;
  }
}

// create 100 font-size variable;
@for $i from 0 through 100 {
  $name: $i + 1;

  .font-size-#{$name} {
    font-size: #{$name + 'px'} !important;
  }
}

@for $i from 0 through 100 {
  $name: $i + 1;

  .minw-#{$name}-percent {
    min-width: #{$name + '%'} !important;
  }
}

// create 1000 min width pixel variable;
@for $i from 0 through 1000 {
  $name: $i + 1;

  .minw-#{$name} {
    min-width: #{$name + 'px'} !important;
  }
}

@for $i from 0 through 100 {
  $name: $i + 1;

  .maxw-#{$name}-percent {
    max-width: #{$name + '%'} !important;
  }
}

// create 1000 max width pixel variable;
@for $i from 0 through 1000 {
  $name: $i + 1;

  .maxw-#{$name} {
    max-width: #{$name + 'px'} !important;
  }
}

@for $i from 0 through 500 {
  $name: $i + 1;

  .minh-#{$name}-px {
    min-height: #{$name + 'px'} !important;
  }
}

@for $i from 0 through 500 {
  $name: $i + 1;

  .maxh-#{$name}-px {
    max-height: #{$name + 'px'} !important;
  }
}

@for $i from 0 through 100 {
  $name: $i + 1;

  .maxh-#{$name}-vh {
    max-height: #{$name + 'vh'} !important;
  }

  .maxh-#{$name}-percent {
    max-height: #{$name + '%'} !important;
  }
}

.ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.swal-alert-custom-button {
  color: #fff !important;
  background-color: var(--primary) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  color: white !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 6px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #e7e7e7;
  border-radius: 15px;
  border: 1px solid #c9c9c9;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
  background-color: #fff;
  border-radius: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.cursor-grab:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  display: block;
  font-family: 'FontAwesome';
  content: '\f0de';
  float: left;
  margin-left: -15px;
  margin-top: 2px;
  background: none;
}

th[sortable].desc:before {
  font-family: 'FontAwesome';
  content: '\f0dd';
  margin-top: -2px;
}

.header-fixo {
  position: sticky;
  top: -1px !important;
  z-index: 2;
}

.ul-lista-aviso {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  // display: flex;
  overflow-y: auto;
}

ul li.with-point:before {
  content: '\2022';
  /* Código Unicode para um ponto • */
  margin-right: 0.5em;
  /* Espaçamento entre o ponto e o texto */
}

.switch-radio-button-group {
  .switch-radio-button-group-label {
    border: 1px solid #d8d6de !important;
  }
}

//  .ng-select.custom {
//     border:0px;
//     min-height: 3.5rem;
//     border-radius: 8px;
// }
// .ng-select.custom .ng-select-container  {
//     min-height: 3.5rem;
//     border-radius: 8px;
// }

// .ng-select.form-control-sm {
//     font-size: 0.857rem !important;
// }

// .ng-select.form-control-sm .ng-select-container  {
//     height: 2.142rem !important;
//     min-height: 2.1rem !important;
// }

// .ng-select.form-control-sm .ng-select-focused:not(.ng-select-opened)>.ng-select-container {
//     border-color: red !important;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)  !important;
// }

.ng-select.form-control-sm1 {
  font-size: 0.9rem;

  .ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: red !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1) !important;
  }

  .ng-select-container {
    height: 2.142rem !important;
    min-height: 2.1rem !important;
  }

  .ng-select-multiple .ng-select-container {
    height: 3px;
  }
}

.modal-maior-lg-menor-xl .modal-dialog {
  max-width: 80%;
}

.modal-99-vw .modal-dialog {
  max-width: 99vw;
}

@for $i from 0 through 100 {
  $name: $i + 1;

  .modal-mw-#{$name}-percent .modal-dialog {
    max-width: #{$name + '%'};
  }
}

.modal-confirmar-pagamento .modal-dialog {
  max-width: 45%;
}

.modal-animation .modal-dialog {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.link {
  text-decoration: underline;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.gap-4 {
  gap: 4rem;
}

.gap-5 {
  gap: 5rem;
}

.gap-25 {
  gap: 0.25rem;
}

.gap-50 {
  gap: 0.5rem;
}

.gap-75 {
  gap: 0.75rem;
}

.bg-disabled {
  background-color: $input-disabled-bg;
}

.list-style-disc {
  list-style: disc;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #ea5455;
  padding-right: calc(1.45em + 0.876rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3625em + 0.219rem) center;
  background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
}

ng-select.is-invalid.ng-untouched .ng-select-container {
  border-color: #ea5455;
  padding-right: calc(1.45em + 0.876rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3625em + 0.219rem) center;
  background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
}

.cdk-overlay-container {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 1050 !important;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 1050 !important;
}

.badge-purple {
  background-color: #512da8;
  color: #ffffff;
}

table tr,
body,
label {
  color: black;
}

.modal-invoice-custo .modal-dialog {
  min-width: 70% !important;
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  height: 13px; /* Define a altura da barra de rolagem horizontal */
  width: 13px; /* Define a altura da barra de rolagem vertical */
}
